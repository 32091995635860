/* Custom properties */
:root {
	/* Grid */
	--max-width: 1400px;
	--max-width-header: 1400px;
	--max-width-list: 1400px;
	--max-width-pagination: 1400px;
	--max-width-content-wide: 1400px;
	--max-width-content: 1400px;
	--grid-gutter-width: 1400px;
	--grid-col-width: 1400px;

	/* Typography */
	// --base-font-family: 'DM Sans', sans-serif;
	--base-font-family: 'DM Sans', sans-serif;
	--heading-font-family: 'Bricolage Grotesque', sans-serif;
	/* --base-font-family: 'Nunito', sans-serif; */

	/* Type: Sizes */
	--base-font-size: autosize(400, 1920, 16, 16);
	--font-size-caption: autosize(400, 1920, 24, 32);
	--font-size-excerpt: autosize(400, 1920, 18, 20);
	--font-size-small: autosize(400, 1920, 14, 14);
	--font-size-large: autosize(400, 1920, 24, 24);
	--font-size-h1: autosize(400, 1920, 20, 24);
	--font-size-h2: autosize(400, 1920, 18, 22);
	--font-size-h3: autosize(400, 1920, 16, 18);
	--font-size-h4: autosize(400, 1920, 16, 18);
	--font-size-h5: autosize(400, 1920, 16, 16);
	--font-size-h6: autosize(400, 1920, 16, 16);
	--font-size-btn: autosize(400, 1920, 14, 14);

	/* Type: Line height */
	--base-line-height: calc(44 / 32);
	--line-height-heading: calc(44 / 32);

	/* Type: Font weight */
	--font-weight-light: 300;
	--font-weight-regular: 400;
	--font-weight-medium: 500;
	--font-weight-semibold: 600;
	--font-weight-bold: 700;
	--font-weight-extrabold: 800;
	--font-weight-black: 900;

	/* Colors */
	--color-1: #8064ff;
	--color-2: #433da2;
	--color-3: #3d4572;
	--color-4: #fffbf8;
	--color-5: #faf2e8;
	--color-6: #c0b19f;
	--color-7: #4f5e8c;
	--color-8: #7d87a3;
	--color-text: var(--color-7);
	--color-text-light: #fcfcfc;
	--color-heading: var(--color-3);
	--color-separator: #d7d7d7;
	--color-gray-1: #fcfcff; // #f9fafc;
	--color-gray-2: #f7f7f9;
	--color-gray-3: #e8e8ee;
	--color-gray-4: #f5f6fa;
	--color-gray-5: #b1b1c5;
	--color-error: #ff82a6;
	--color-error-text: #8e2d4a;
	--color-success: #06ad19;
	--color-warning: #f6da6b;
	--color-field-border: var(--color-gray-3);

	--color-element: #e8e8e8;
	--color-element-hover: #d7d7d7;

	/* Link colors */
	--base-link-color: var(--color-4);
	--base-link-color-2: #6f91dd;
	--hover-link-color: var(--color-2);
	--base-button-color: var(--color-1);
	--hover-button-color: #8064ffe0;
	--disabled-button-color: #3d6edd95;

	/* Labels */
	--label-green-text-color: #37ba5c;
	--label-green-bg-color: #c4ffd3;
	--label-blue-text-color: #605c9c;
	--label-blue-bg-color: #eae8ff;

	/* Border color */
	--base-border-color: #f6f6f7;
	--dark-border-color: #ddd;
	--base-border: 0.078rem solid #f0f0f5;
	--dark-border: 1px solid var(--dark-border-color);
	--light-border-color: #f0f0f0;
	--light-border: 1px solid var(--light-border-color);
	--border-radius: 3px;
	--border-radius-large: 12px;

	/* Shadow color */
	--base-shadow-color: #fcfcfc;

	/* Box shadow */
	--card-shadow: 0 4px 8px rgb(0 0 0 / 2%);
	--panel-shadow: 0 4px 8px rgb(0 0 0 / 15%);

	/* box-color */
	--box-color: #f4f4f4;

	/* Spacing */
	--spacing-1: 0.625rem;
	--spacing-2: 1.25rem;
	--spacing-3: 1.875rem;
	--spacing-4: 2.5rem;
	--spacing-5: 3.125rem;
	--spacing-6: 3.75rem;
	--spacing-7: 4.375rem;
	--spacing-8: 5rem;
	--spacing-9: 5.625rem;
	--spacing-10: 6.25rem;
}
