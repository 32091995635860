.conversation__messages {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: var(--spacing-1);

	&-roles {
		display: flex;
		justify-content: space-between;
		text-transform: uppercase;
		font-weight: var(--font-weight-bold);
	}

	&-item {
		display: inline-flex;
		border-radius: 4px;
		word-break: break-word;
		line-height: 1.5;

		&.role--assistant {
			text-align: left;
			background-color: #f9f9f9;
			padding: var(--spacing-1);
			max-width: 85%;
			margin-right: auto;
		}

		&.role--user {
			max-width: 85%;
			margin-left: auto;
			background-color: var(--color-3);
			padding: var(--spacing-1);
			color: #fff;
		}
	}
}
