h1,
h2,
h3,
h4,
h5,
h6,
.MuiTypography-root {
	font-family: var(--base-font-family);
	color: var(--color-heading);
	font-weight: var(--font-weight-bold);
	line-height: var(--line-height-heading);
}

h1,
.MuiTypography-h1 {
	font-size: var(--font-size-h1);
}

h2,
.MuiTypography-h2 {
	font-size: var(--font-size-h2);
}

h3,
.MuiTypography-h3 {
	font-size: var(--font-size-h3);
}

h4,
.MuiTypography-h4 {
	font-size: var(--font-size-h4);
}

h5,
.MuiTypography-h5 {
	font-size: var(--font-size-h5);
}

h6,
.MuiTypography-h6 {
	font-size: var(--font-size-h6);
}

.MuiTypography-p,
p {
	font-family: var(--base-font-family);
	color: var(--color-text);
	line-height: var(--base-line-height);
}
