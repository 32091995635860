.btn {
	color: var(--base-link-color);
	background-color: var(--color-1);
	box-shadow: none;
	font-family: var(--heading-font-family);
	font-size: var(--font-size-btn);
	font-weight: var(--font-weight-medium);
	text-transform: none;
	width: max-content;
	gap: 7px;
	letter-spacing: 0;
	max-height: 38px;
	border-radius: 12px;

	&:hover {
		color: #fff;
		background-color: var(--hover-button-color);
		box-shadow: none;
	}

	&:focus {
		background-color: var(--hover-button-color);
		box-shadow: none;
	}

	svg {
		width: 18px;
		height: 18px;
	}

	&.size--small {
		font-size: var(--font-size-small);
		padding: 6px 10px;
	}

	&.secondary-btn {
		color: var(--label-blue-text-color);
		background-color: var(--label-blue-bg-color);
		font-weight: var(--font-weight-regular);
	}

	&.filter-btn {
		width: auto;
		min-width: max-content;
		height: 38px;
		font-family: var(--base-font-family);
		font-weight: var(--font-weight-regular);
		background-color: #fff;
		color: var(--color-3);
		box-shadow: none;
		border-radius: 12px;
		border: 1px solid var(--color-gray-3);
	}

	&.danger-btn {
		background-color: var(--color-error);
		color: var(--color-error-text);
	}

	&.position--right {
		margin-left: auto;
	}
}

.MuiButtonBase-root.MuiButton-textPrimary {
	min-width: 64px;
	padding: 6px 16px;
	color: var(--label-blue-text-color);
	background-color: var(--label-blue-bg-color);
	box-shadow: none;
	font-family: var(--heading-font-family);
	font-size: var(--font-size-btn);
	font-weight: var(--font-weight-regular);
	text-transform: none;
	width: max-content;
	gap: 7px;
	border-radius: 12px;
	letter-spacing: 0;

	.MuiButton-startIcon {
		margin: 0;
	}
}
