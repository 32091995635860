.left-panel {
	width: 100%;
	display: flex;
	flex-direction: column;
	background-color: var(--color-gray-1);
	border-right: 1px solid var(--base-border-color);
	height: 100vh;
	position: sticky;
	top: 0;
	overflow: hidden;

	&__top {
		width: 100%;
		display: flex;
		align-items: center;
		padding: var(--spacing-4) var(--spacing-2) 0;

		img {
			width: 50px;
		}

		&-plans {
			display: flex;
			flex-direction: column;
			padding: 0 0 0 10px;

			&-name {
				color: var(--color-heading);
				font-weight: var(--font-weight-bold);
				font-size: 1rem;
			}

			&-type {
				color: var(--color-1);
				font-weight: var(--font-weight-medium);
				font-size: 14px;
			}
		}
	}

	&__nav {
		width: 100%;
		height: 100%;
		margin: autosize(1041, 1920, 30, 120) 0 0;

		ul {
			width: 100%;
			height: 100%;
			margin: 0;
			padding: 0;
			list-style-type: none;
			display: flex;
			flex-direction: column;

			a {
				text-decoration: none;

				&:hover,
				&:focus,
				&:active {
					text-decoration: none;
				}
			}

			li {
				display: flex;
				align-items: center;
				margin: 0;
				padding: 13px var(--spacing-4);
				margin: 2px 0;
				position: relative;

				svg {
					z-index: 1;
					color: #aabac8;
					margin: 0 var(--spacing-2) 0 0;
					transition: 50ms linear;
				}

				span {
					color: #6c6e75;
					z-index: 1;
					font-family: var(--base-font-family);
					font-size: 1rem;
					font-weight: var(--font-weight-semibold);
					transition: 50ms linear;
				}

				label {
					z-index: 1;
					background-color: #e84474;
					padding: 4px 10px;
					border-radius: 15px;
					color: #fff;
					font-weight: var(--font-weight-medium);
					margin-left: var(--spacing-1);
					font-size: 12px;

					&.coming-soon {
						color: #86e6a1;
						background-color: #e3ffea;
					}
				}

				&:last-of-type {
					margin-bottom: 0;
				}

				&:hover {
					cursor: pointer;

					svg {
						color: var(--color-heading);
					}

					span {
						color: #3a4652;
					}
				}
			}

			a.active li {
				cursor: pointer;

				svg {
					color: var(--color-heading);
				}

				span {
					color: #6c6e75;
				}

				&::after {
					content: '';
					position: absolute;
					left: 0;
					top: 0;
					width: calc(100% - var(--spacing-4));
					height: 100%;
					margin: 0 var(--spacing-2);
					background-color: #f2f4f8;
					border-radius: 10px;
				}
			}

			a.left-panel__nav-spacer {
				display: block;
				margin: var(--spacing-3) 0 0;
			}

			a.left-panel__nav-end {
				margin-top: auto;
			}
		}
	}

	&__user {
		margin-top: auto;

		&-btn.btn {
			width: calc(100% - var(--spacing-4));
			margin: var(--spacing-2);

			svg {
				color: #ffffff90;
			}
		}

		&-info {
			width: 100%;
			border-top: 2px solid #f6f6f7;
			padding: var(--spacing-2);
			display: flex;
			flex-direction: row;
			align-items: center;
		}

		&-names {
			width: 100%;
			font-weight: var(--font-weight-regular);
			margin: 0 0 0 var(--spacing-1);
			color: #778684;
			font-size: autosize(1040, 1920, 16, 18);
		}

		&-settings {
			margin-left: auto;

			&-menu ul {
				padding: 0;

				a {
					color: #778684;
					text-decoration: none;

					&:focus,
					&:hover {
						color: var(--color-1);
					}
				}

				li {
					font-family: var(--base-font-family);
					padding: 12px 15px;
					font-size: 0.875rem;
					font-weight: var(--font-weight-regular);

					&:hover,
					&:active,
					&:focus {
						background-color: #f8f9fc;
					}
				}
			}
		}
	}
}
