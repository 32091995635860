.stats-panel {
	width: 100%;
	display: flex;
	gap: var(--spacing-2);

	&__item {
		width: auto;
		padding: var(--spacing-3) var(--spacing-2);
		border: var(--base-border);
		border-radius: rem(12);
		color: var(--color-heading);

		&-label {
			font-weight: var(--font-weight-bold);
			font-size: 1rem;
		}

		&-value {
			font-size: var(--font-size-h1);
			font-weight: var(--font-weight-bold);
			line-height: var(--line-height-heading);
		}

		&.stats-red {
			background-color: #e84474;
		}
	}
}
