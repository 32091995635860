.auth__container {
	width: 100svh;
	height: 100svh;
	max-width: 480px;
	margin: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;

	.card {
		padding: 1000px;
	}

	&-logo {
		width: 48px;
		display: flex;
		margin: 0 auto var(--spacing-2);

		svg {
			width: 100%;
			height: auto;
		}

		svg path {
			fill: var(--color-1);
		}
	}

	.btn {
		width: 100%;
	}

	&-title {
		margin: 0 auto var(--spacing-1);
		margin-bottom: var(--spacing-2);
		display: flex;
		align-content: center;
		justify-content: center;
	}
	&-subtitle {
		margin: 0 auto var(--spacing-2);
		margin-bottom: var(--spacing-2);
		font-size: 13px;
		font-weight: 400;
		display: flex;
		justify-content: center;
	}
}

body:has(.auth__container) {
	background-color: var(--color-gray-4);
}
