.cards {
	width: 100%;
	display: grid;
	gap: var(--spacing-2);

	&.grid--1 {
		grid-template-columns: 1fr;

		.MuiSkeleton-root {
			min-height: 600px;
			max-height: 600px;
		}
	}

	&.grid--2 {
		grid-template-columns: repeat(2, minmax(0, 1fr));

		.MuiSkeleton-root {
			min-height: 450px;
			max-height: 450px;
		}
	}

	&.grid--3 {
		grid-template-columns: repeat(3, minmax(0, 1fr));

		.MuiSkeleton-root {
			min-height: 300px;
			max-height: 300px;
		}
	}

	&.grid--4 {
		grid-template-columns: repeat(4, minmax(0, 1fr));

		.MuiSkeleton-root {
			min-height: 300px;
			max-height: 300px;
		}
	}

	&.grid--5 {
		grid-template-columns: repeat(5, minmax(0, 1fr));

		.MuiSkeleton-root {
			min-height: 300px;
			max-height: 300px;
		}
	}

	&.columns--2-1 {
		.card,
		.cards {
			&:nth-child(1) {
				grid-column: 1 / 3;
			}
		}

		.card,
		.cards {
			&:nth-child(2) {
				grid-column: 3 / 3;
			}
		}
	}

	&.columns--3-2 {
		.card,
		.cards {
			&:nth-child(1) {
				grid-column: 1 / 4;
			}
		}

		.card,
		.cards {
			&:nth-child(2) {
				grid-column: 4 / 6;
			}
		}
	}

	&.columns--3-1 {
		.card,
		.cards {
			&:nth-child(1) {
				grid-column: 1 / 4;
			}
		}

		.card,
		.cards {
			&:nth-child(2) {
				grid-column: 4 / 4;
			}
		}
	}

	&.stacked {
		// display: flex;
		// flex-direction: column;
		// gap: var(--spacing-2);
		// grid-template-rows: auto;
		height: 100%;
		min-height: 100%;
		// max-height: 600px;

		&.grid--2 {
			grid-template-rows: repeat(2, minmax(0, 1fr));
			grid-template-columns: 1fr;
		}

		.card {
			grid-column: unset !important;
		}
	}

	&.type--grey {
		border-radius: 8px;
		background-color: var(--color-gray-4);
		padding: var(--spacing-1);
	}

	.MuiSkeleton-root {
		width: 100%;
		height: 100%;
		min-height: 300px;
		max-height: 300px;
	}
}

.card {
	width: 100%;
	height: auto;
	border: 0.078rem solid #f0f0f5;
	padding: var(--spacing-2);
	background-color: #fff;
	box-shadow: var(--card-shadow);
	border-radius: 8px;
	display: flex;
	flex-direction: column;

	.cards.grid--3 & {
		max-height: 500px;
	}

	&__header {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		gap: var(--spacing-1);
		margin-bottom: var(--spacing-1);

		.download-button-group {
			margin-left: auto;
		}
	}

	&__title {
		font-weight: var(--font-weight-regular);
		font-size: var(--base-font-size);
		letter-spacing: -0.5px;
		display: flex;
		align-items: center;
		gap: 8px;
		width: 100%;

		svg {
			font-size: 20px;
		}
	}

	&__description {
		font-family: var(--base-font-family);
		font-size: 14px;
		color: var(--color-8);
		display: flex;
		flex-direction: column;
		flex-direction: wrap;

		b {
			color: var(--color-1);
		}
	}

	&__value {
		flex-basis: 100%;
		font-size: 36px;
		font-weight: 800;

		.insights-panel__content & {
			font-size: var(--font-size-large);
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
		gap: var(--spacing-2);
		position: relative;
		overflow: hidden;
		height: 100%;

		.has-card-list & {
			&::after {
				width: 100%;
				content: '';
				position: absolute;
				height: 50px;
				background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
				z-index: 1;
				bottom: 0;
				pointer-events: none;
			}
		}
	}

	&__options-group {
		margin-left: auto;
	}

	&__list {
		margin: 0;
		padding: 0;
		list-style-type: none;
		display: flex;
		flex-direction: column;
		gap: 6px;
		// max-height: 250px;
		overflow-y: scroll;
		position: relative;
		// scrollbar-width: none; /* Firefox */
		// -ms-overflow-style: none; /* IE and Edge */
		position: relative;

		li {
			display: flex;
			align-items: center;
			margin: 0;
			padding: 9px 6px;
			margin: 0;
			position: relative;
			gap: 12px;
			border-radius: 10px;
			transition: none;

			&:hover {
				cursor: pointer;
				background-color: #fff;

				svg,
				span {
					color: var(--color-2);
				}

				label {
					cursor: pointer;
				}
			}

			svg {
				z-index: 1;
				color: var(--color-text);
				transition: 50ms linear;
				width: 22px;
				height: 22px;
			}

			span {
				color: var(--color-text);
				z-index: 1;
				font-family: var(--base-font-family);
				font-size: 15px;
				font-weight: var(--font-weight-regular);
				transition: 50ms linear;
				line-height: 1.1;
				width: auto;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
			}

			// &:nth-last-of-type(2) {
			// 	z-index: 2;
			// }

			&:last-of-type {
				margin-bottom: 0;
				border-bottom: none;
				z-index: 2;
			}
		}
	}

	&__grid-list {
		border-color: #f0f0f5;

		.MuiDataGrid-toolbarContainer {
			padding: var(--spacing-1);
			border-bottom: 0.078rem solid #f0f0f5;
		}

		&.MuiDataGrid-root {
			color: var(--color-text);

			.MuiDataGrid-cell {
				font-size: 13px;
			}
		}

		&-icon-button {
			color: var(--color-text);

			svg {
				font-size: 20px;
			}
		}
	}

	.cards.type--grey & {
		border: none;
	}

	&.type--grey {
		background-color: var(--color-gray-1);
		box-shadow: none;
		border: none;
	}

	&.type--clear {
		padding: 0;
		border: none;
		box-shadow: none;
	}

	&.type--border {
		box-shadow: none;
	}
}
