html,
body {
	margin: 0;
	padding: 0;
}

* {
	box-sizing: border-box;
	font-family: var(--base-font-family);
}

.app {
	display: grid;
	grid-template-columns: 350px 1fr;
	/* 
	@media (max-width: 1041px) {
		display: none;
	} */
}
