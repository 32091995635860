.basic__modal-box {
	max-height: 80svh;
	overflow-y: scroll;
	border: 0;
	border-radius: var(--border-radius-large);
	min-height: 400px;
	position: relative;

	&-header {
		width: 100%;
		height: auto;
		display: flex;
		flex-direction: column;
		padding: var(--spacing-1) 0;
		gap: var(--spacing-1);
	}

	&-close {
		position: absolute;
		right: 16px;
		top: 16px;

		svg {
			font-size: 20px;
		}
	}
}
