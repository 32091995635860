.button-spacing{
    flex: auto;
    justify-content: space-between;
    margin-top: 10px;
    margin-right: 20px;
  }
  
  .button-spacing:last-child {
    margin-right: 0;
  }
.danger{
    background-color: red !important;
    color: white !important;
}
.basic__modal-box-footer{
    display: flex;
    margin-top: -1px;
}
.confirmation__modal-box{
    padding-top: 5px;
}